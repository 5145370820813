import {Translate} from "../../hooks/translate.hook";
import {SmartColumn} from "../common/smart-table/smart-table.model";
import {FieldType} from "../common/smart-table/smart-table.field-config";

export const columnFactory = (t: Translate): SmartColumn<any>[] => {
    return [
        {
            name: 'agentNumber',
            visibleName: t('AGENT_TITLE'),
            type: FieldType.OBJECT,
            options: {t: 'clients', f: 'agentNumber'},
            filterable: true
        },
        {
            name: 'countryCode',
            visibleName: t('COUNTRY'),
            type: FieldType.STRING,
            filterable: false
        },
        {
            name: 'vatNumber',
            visibleName: t('VAT.NUMBER'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'vatNumberIsValid',
            visibleName: t('VAT.IS_VALID'),
            type: FieldType.BOOLEAN,
            filterable: true
        },
        {
            name: 'companyName',
            visibleName: t('COMPANY_NAME'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'viesCompanyName',
            visibleName: t('VAT.COMPANY_NAME_IN_VIES'),
            type: FieldType.STRING,
            filterable: true
        },
        {
            name: 'legalNameIsAccepted',
            visibleName: t('VAT.COMPANY_NAME_ACCEPTED'),
            type: FieldType.BOOLEAN,
            filterable: true
        },
        {
            name: 'valid',
            visibleName: t('VALID'),
            type: FieldType.BOOLEAN,
            filterable: true
        }
    ]
}