import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {PaginatedRequest} from "../../models/core/paginated.request";
import {PaginatedResult} from "../../models/core/paginated.result";
import {SystemUser} from "../../models/users/user/system.user";

export class VatValidationService {
    private api: AxiosInstance = API;

    private responsibleUsers: Promise<SystemUser[]> | null = null;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<any>> {
        return this.api.get<PaginatedResult<any>>(
            'vat-validator',
            {
                params: request.toParams(),

            }).then(x => x.data);
    }

    validate(id: number): Promise<any> {
        return this.api.post('vat-validator/address/' + id + '/validate');
    }

    acceptAddress(id: number): Promise<any> {
        return this.api.post('vat-validator/address/' + id + '/accept-address');
    }
}

export const vatValidationService = new VatValidationService();
