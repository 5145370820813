import React, {useMemo, useState} from "react";
import {Space, Typography} from "antd";
import {SmartTable} from "../common/smart-table/smart-table";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {useTranslate} from "../../hooks/translate.hook";
import {SmartTableDataHandler} from "../common/smart-table/smart-table.data-handler";
import {columnFactory} from "./vat-validation.columns";
import {vatValidationService} from "../../services/vat-validation/vat-validation.service";
import {notifications} from "../../services/notifications/notifications";

export const VatValidation: React.FC<ComponentPropsFromRoute> = () => {

    const t = useTranslate();

    const handler = useMemo(() => new SmartTableDataHandler(r => vatValidationService.getAll(r)), []);
    const columns = useMemo(() => columnFactory(t), [t])
    const [disabled, setDisabled] = useState<boolean>(false);

    const validate = async (id: number) => {
        setDisabled(true);
        try {
            await vatValidationService.validate(id);
            await handler.reloadItemsWithLastRequest();
            notifications.successfully();
        } finally {
            setDisabled(false);
        }
    }

    const acceptAddress = async (id: number) => {
        setDisabled(true);
        try {
            await vatValidationService.acceptAddress(id);
            await handler.reloadItemsWithLastRequest();
            notifications.successfully();
        } finally {
            setDisabled(false);
        }
    }

    return (
        <>
            <Space size={[10, 10]} direction={"vertical"}>
                <Typography.Title level={3} style={{margin: "5 0px"}}>
                    {t("VAT-VALIDATION.TITLE")}
                </Typography.Title>

                <SmartTable
                    filterGroups={[
                        {
                            name: 'NOT_VALID',
                            fields: [
                                {name: 'valid', value: '0'}
                            ]
                        },
                        {
                            name: 'NOT_APPROVED_NAME',
                            fields: [
                                {name: 'legalNameIsAccepted', value: '0'}
                            ]
                        },
                        {
                            name: 'VALID',
                            fields: [
                                {name: 'valid', value: '1'}
                            ]
                        }
                    ]}
                    columns={columns}
                    handler={handler}
                    disabledActions={disabled}
                    actions={[
                        {
                            title: 'Validate',
                            icon: 'sync',
                            onClick: (v) => validate(v.id),
                        },
                        {
                            title: 'Accept address',
                            icon: 'check-circle',
                            onClick: (v) => acceptAddress(v.id),
                        }
                    ]}
                />
            </Space>
        </>
    )
}