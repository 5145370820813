import {SmartAction, SmartColumn, SmartFilterNameAndValue} from "./smart-table.model";
import {ColumnsType} from "antd/es/table";
import {ColumnType, FilterValue} from "antd/es/table/interface";
import {FilterDropdown} from "./filter/dropdown/smart-table.filter.dropdown";
import {configMap, FieldConfig} from "./smart-table.field-config";
import {parse, stringify} from "query-string";
import {FilterValues} from "../../../models/filter/filter.props";
import {filterRuleMap} from "./filter/rule/smart-table.filter.rule";
import {Button, Dropdown, Menu, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const smartColumnsToAntdColumns = (columns: SmartColumn<any>[], map: Record<string, FieldConfig>, fValues: FilterValues, actions: SmartAction<any>[], disabled: boolean): ColumnsType<any> => {
    const result = columns.map(column => {
        const fieldConfig = map[column.name];
        if (!fieldConfig) {
            throw new Error('Failed to get config for column ' + column.name);
        }

        const data: ColumnType<any> = {
            title: column.visibleName,
            key: column.name,
            dataIndex: column.dataIndex || column.name
        }

        if (column.filterable) {
            data.filterDropdown = (props) => <FilterDropdown config={fieldConfig} column={column} {...props} />;
        }

        // if (column.sortable) {
        //     data.sorter = (a, b) => 0
        // }

        if (fValues[column.name]) {
            data.defaultFilteredValue = fieldConfig.denormalize(fValues[column.name] as string);
        }

        if (column.render) {
            data.render = column.render;
        } else {
            data.render = (v, rec) => {
                return fieldConfig.render(v, rec, column);
            }
        }

        return data
    })

    if (actions.length > 0) {
        result.push({
            title: '',
            key: 'actions',
            width: 100,
            render: v =>
                actions.length > 2 ? (
                    <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                            <Menu>
                                {actions.filter(a => !a.isVisible || a.isVisible(v)).map((a, i) => (
                                    <Menu.Item
                                        key={i}
                                        icon={<FontAwesomeIcon icon={a.icon} />}
                                        onClick={() => a.onClick(v)}
                                        disabled={disabled}
                                    >
                                        {a.title}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                    >
                        <Button
                            type={"text"}
                            icon={<FontAwesomeIcon icon={["fas", "ellipsis-v"]} />}
                            disabled={disabled}
                        >
                        </Button>
                    </Dropdown>
                ) : (
                    <Space>
                        {actions.filter(a => !a.isVisible || a.isVisible(v)).map((a, i) => (
                            <Tooltip title={a.title}>
                                <Button
                                    key={i}
                                    size={"small"}
                                    type={"text"}
                                    onClick={() => a.onClick(v)}
                                    icon={<FontAwesomeIcon icon={a.icon} />}
                                    disabled={disabled}
                                />
                            </Tooltip>
                        ))}
                    </Space>
                )
        })
    }

    return result;
}

export const smartColumnsToNameFieldConfigMap = (columns: SmartColumn<any>[]): Record<string, FieldConfig> => {
    const value: Record<string, FieldConfig> = {}
    columns.forEach(column => {
        value[column.name] = configMap[column.type];
    })

    return value;
}

export const smartSplitToRuleAndValue = (value: string, cfg: FieldConfig): [string, string] => {
    let prefix = '';

    cfg.rules.forEach(rule => {
        const f = filterRuleMap[rule].value;
        if (f.length && value.startsWith(f)) {
            prefix = value.substr(0, f.length)
            value  = value.substr(f.length)
            return false;
        }
    })

    return [prefix, value];
}

export const antFiltersToSmartFilters = (filters: Record<string, FilterValue | null>, map: Record<string, FieldConfig>): SmartFilterNameAndValue[] => {
    return Object
        .keys(filters)
        .filter(v => filters[v] !== null)
        .map(name => ([name, map[name].normalize(filters[name] as string[])]))
}

export const stringifySmartFilters = (filters: FilterValues): string => {
    return stringify(filters)
}

export const parseSmartFilters = (value: string): FilterValues => {
    return parse(value) as FilterValues;
}